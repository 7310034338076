.CenterWrapper{
    display: flex;
    align-items: center;
    width: 300px;
    margin: 5% 15% 0 15%;
    
}

.loginContainer{
    width: 600px;
}

@media only screen and (min-width: 600px) {
    .CenterWrapper{
        width: 70%;
    }
}


#passwordHelpBlockError{
    --bs-text-opacity: 1;
    color:red
}

#passwordHelpBlocknormal {
    --bs-text-opacity: 1;
    color: #6c757d;
}
